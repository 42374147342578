import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { accountSlice } from '../features/account/account.slice';
import { advisorSlice } from '../features/advisor/advisor.slice';
import { advisorProfileSlice } from '../features/advisorProfile/advisorProfile.slice';
import { alertSlice } from '../features/alert/alert.slice';
import { authSlice } from '../features/auth/auth.slice';
import { clientSlice } from '../features/client/client.slice';
import { houseModelSlice } from '../features/houseModel/houseModel.slice';
import { mddSlice } from '../features/mdd/mdd.slice';
import { roleSlice } from '../features/role/role.slice';
import { serviceProviderSlice } from '../features/serviceProvider/serviceProvider.slice';
import { transactionSlice } from '../features/transaction/transaction.slice';
import { transactionTypeSlice } from '../features/transactionType/transactionType.slice';
import { userSlice } from '../features/user/user.slice';
import { notificationSlice } from '../features/notification/notification.slice';
import { formSlice } from '../features/form/form.slice';

//Old reducers
import { fund } from '../redux/reducers/fund.reducer';
// import { transactionType } from '../redux/reducers/transactionType.reducer';
import { calculation } from '../redux/reducers/calculation.reducer';
import { entity } from '../redux/reducers/entity.reducer';
import { fileStorage } from '../redux/reducers/fileStorage.reducer';
import { product } from '../redux/reducers/product.reducer';
import { userFunction } from '../redux/reducers/userFunction.reducer';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    alert: alertSlice.reducer,
    account: accountSlice.reducer,
    transaction: transactionSlice.reducer,
    serviceProvider: serviceProviderSlice.reducer,
    houseModel: houseModelSlice.reducer,
    mdd: mddSlice.reducer,
    user: userSlice.reducer,
    advisorProfile: advisorProfileSlice.reducer,
    client: clientSlice.reducer,
    advisor: advisorSlice.reducer,
    role: roleSlice.reducer,
    transactionType: transactionTypeSlice.reducer,
    form: formSlice.reducer,
    notification: notificationSlice.reducer,

    //-- Old reducers --//
    fund,
    product,
    userFunction,
    entity,
    calculation,
    fileStorage
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
