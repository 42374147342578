import { fundConstants } from '../constants';
import { fundService } from '../services';
import { alertActions } from './';

export const fundActions = {
    getAllFunds,
    getFund,
    createFund
};


function getAllFunds(query) {
    return dispatch => {
        dispatch(request());

        return fundService.getAllFunds(query)
            .then(
                funds => { 
                    dispatch(success(funds));
                },
                error => {
                    const err = error.response.data;

                    dispatch(failure(err.msg));
                    dispatch(alertActions.error(err.msg));
                }
            );
    };

    function request() { return { type: fundConstants.GET_FUNDS_REQUEST } }
    function success(funds) { return { type: fundConstants.GET_FUNDS_SUCCESS, funds } }
    function failure(error) { return { type: fundConstants.GET_FUNDS_FAILURE, error } }
}

function getFund(id) {
    return dispatch => {
        dispatch(request());

        return fundService.getFund(id)
            .then(
                fund => { 
                    dispatch(success(fund));
                },
                error => {
                    const err = error.response.data;

                    dispatch(failure(err.msg));
                    dispatch(alertActions.error(err.msg));
                }
            );
    };

    function request() { return { type: fundConstants.GET_FUND_REQUEST } }
    function success(fund) { return { type: fundConstants.GET_FUND_SUCCESS, fund } }
    function failure(error) { return { type: fundConstants.GET_FUND_FAILURE, error } }
}

function createFund(data) {
    return dispatch => {
        dispatch(request());

        return fundService.createFund(data)
            .then(
                fund => { 
                    dispatch(success(fund));
                },
                error => {
                    const err = error.response.data;

                    dispatch(failure(err.msg));
                    dispatch(alertActions.error(err.msg));
                }
            );
    };

    function request() { return { type: fundConstants.CREATE_FUND_REQUEST } }
    function success(fund) { return { type: fundConstants.CREATE_FUND_SUCCESS, fund } }
    function failure(error) { return { type: fundConstants.CREATE_FUND_FAILURE, error } }
}