import { productConstants } from "../constants";
import { productService } from "../services";
import { alertActions } from "./";

export const productActions = {
  getAll,
  getById,
  create,
  update,
  remove,
  getDestinctProductsbyServiceProvider,
  getProductMappings,
  updateProductMapping,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    return productService.getAll().then(
      (products) => {
        dispatch(success(products));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_ALL_REQUEST };
  }
  function success(products) {
    return { type: productConstants.GET_ALL_SUCCESS, products };
  }
  function failure(error) {
    return { type: productConstants.GET_ALL_FAILURE, error };
  }
}

function getDestinctProductsbyServiceProvider(serviceprovider) {
  return (dispatch) => {
    dispatch(request());

    return productService
      .getDestinctProductsbyServiceProvider(serviceprovider)
      .then(
        (products) => {
          dispatch(success(products));
        },
        (error) => {
          const err = error.response.data;

          dispatch(failure(err.msg));
          dispatch(alertActions.error(err.msg));
        }
      );
  };

  function request() {
    return { type: productConstants.GET_BYSERVICEPROVIDER_REQUEST };
  }
  function success(products) {
    return { type: productConstants.GET_BYSERVICEPROVIDER_SUCCESS, products };
  }
  function failure(error) {
    return { type: productConstants.GET_BYSERVICEPROVIDER_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    return productService.getById(id).then(
      (product) => {
        dispatch(success(product));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_BYID_REQUEST };
  }
  function success(product) {
    return { type: productConstants.GET_BYID_SUCCESS, product };
  }
  function failure(error) {
    return { type: productConstants.GET_BYID_FAILURE, error };
  }
}

function create(newProduct) {
  return (dispatch) => {
    dispatch(request());

    return productService.create(newProduct).then(
      (product) => {
        dispatch(success(product));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.CREATE_REQUEST };
  }
  function success(product) {
    return { type: productConstants.CREATE_SUCCESS, product };
  }
  function failure(error) {
    return { type: productConstants.CREATE_FAILURE, error };
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(request());

    return productService.update(id, data).then(
      (product) => {
        dispatch(success(product));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.UPDATE_REQUEST };
  }
  function success(product) {
    return { type: productConstants.UPDATE_SUCCESS, product };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_FAILURE, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());

    return productService.remove(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.DELETE_REQUEST };
  }
  function success(response) {
    return { type: productConstants.DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_FAILURE, error };
  }
}

function getProductMappings(serviceProvider) {
  return (dispatch) => {
    dispatch(request());

    return productService.getProductMappings(serviceProvider).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.GET_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: productConstants.GET_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: productConstants.GET_MAPPING_FAILURE, error };
  }
}

function updateProductMapping(data) {
  return (dispatch) => {
    dispatch(request());

    return productService.updateProductMapping(data).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        const err = error.response.data;

        dispatch(failure(err.msg));
        dispatch(alertActions.error(err.msg));
      }
    );
  };

  function request() {
    return { type: productConstants.UPDATE_MAPPING_REQUEST };
  }
  function success(result) {
    return {
      type: productConstants.UPDATE_MAPPING_SUCCESS,
      result,
    };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_MAPPING_FAILURE, error };
  }
}
