import { useDispatch, useSelector } from 'react-redux';
import ReportProgress from '../../ui/reportProgress.js';
import { Button } from '@mui/material';
import { executeClientDataReport } from '../../../features/account/account.slice.js';

const GenerateClientDataReport = () => {
  const dispatch = useDispatch();
  const jobId = useSelector((state) => state.account.clientDataReport?.jobId);

  const generateClientDataReport = () => {
    dispatch(executeClientDataReport());
  };

  return (
    <div>
      <h1>Generate Client Data Report</h1>
      <Button variant="contained" color="primary" onClick={generateClientDataReport}>
        Generate Report
      </Button>
      <ReportProgress jobId={jobId} type={'job'} />
    </div>
  );
};

export default GenerateClientDataReport;
