export const productConstants = {
  GET_ALL_REQUEST: "GET_ALL_PRODUCT_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_PRODUCT_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_PRODUCT_FAILURE",

  GET_BYID_REQUEST: "GET_BYID_PRODUCT_REQUEST",
  GET_BYID_SUCCESS: "GET_BYID_PRODUCT_SUCCESS",
  GET_BYID_FAILURE: "GET_BYID_PRODUCT_FAILURE",

  CREATE_REQUEST: "CREATE_PRODUCT_REQUEST",
  CREATE_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_FAILURE: "CREATE_PRODUCT_FAILURE",

  UPDATE_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_FAILURE: "UPDATE_PRODUCT_FAILURE",

  DELETE_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_FAILURE: "DELETE_PRODUCT_FAILURE",

  GET_BYSERVICEPROVIDER_REQUEST: "GET_PRODUCTS_BYSERVICEPROVIDER_REQUEST",
  GET_BYSERVICEPROVIDER_SUCCESS: "GET_PRODUCTS_BYSERVICEPROVIDER_SUCCESS",
  GET_BYSERVICEPROVIDER_FAILURE: "GET_PRODUCTS_BYSERVICEPROVIDER_FAILURE",

  GET_MAPPING_REQUEST: "GET_MAPPING_PRODUCTS_REQUEST",
  GET_MAPPING_SUCCESS: "GET_MAPPING_PRODUCTS_SUCCESS",
  GET_MAPPING_FAILURE: "GET_MAPPING_PRODUCTS_FAILURE",

  UPDATE_MAPPING_REQUEST: "UPDATE_MAPPING_PRODUCTS_REQUEST",
  UPDATE_MAPPING_SUCCESS: "UPDATE_MAPPING_PRODUCTS_SUCCESS",
  UPDATE_MAPPING_FAILURE: "UPDATE_MAPPING_PRODUCTS_FAILURE",
};
