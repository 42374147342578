import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fundActions } from '../../../redux/actions';
import {
  Box,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import PrimaryButton from '../../ui/buttons/primaryButton';
import FCard from '../../ui/fairtreeComponents/card/fCard';

const schema = yup.object().shape({
  search: yup.string()
});

function Funds() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');

  const funds = useSelector((state) => state.fund.funds);
  const totalPages = useSelector((state) => state.fund.totalPages);
  //const totalPages = useSelector(state => state.fund.fundQuery.totalPages);

  useEffect(() => {
    retrieveTutorials();
  });

  const retrieveTutorials = () => {
    dispatch(fundActions.getAllFunds({ page, pageSize, search }));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    retrieveTutorials();
  };

  const onSubmit = (data) => {
    setSearch(data.search);
    retrieveTutorials();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: '100%', maxWidth: '700px' }}>
        <FCard sx={{ mb: 2 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Input
                id="search"
                {...register('search')}
                label="Search"
                error={!!errors.search}
                helperText={errors?.search?.message}
              />
            </CardContent>
            <CardActions
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <PrimaryButton>Search</PrimaryButton>
            </CardActions>
          </Form>
        </FCard>
        <FCard sx={{ mb: 2 }}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {funds
                  ? funds.map((fund) => (
                      <TableRow key={fund._id}>
                        <TableCell>{fund.name}</TableCell>
                        <TableCell>{fund.jseCode}</TableCell>
                        <TableCell>{fund.fundType}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            <Pagination
              sx={{
                margin: theme.spacing(3)
              }}
              count={totalPages}
              page={page}
              siblingCount={3}
              boundaryCount={2}
              color="primary"
              onChange={handlePageChange}
            />
          </CardContent>
        </FCard>
      </Box>
    </Box>
  );
}

export default Funds;
