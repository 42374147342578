import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AdminRoute from './hoc/adminRoute';
import AdvisorRoute from './hoc/advisorRoute';
import PrivateRoute from './hoc/privateRoute';
import PublicRoute from './hoc/publicRoute';
import VerifyRoute from './hoc/verifyRoute';

import { UserFunctions } from './helpers/access';

//Account
import Profile from './components/account/profile';
import Balances from './components/account/balancesView';
import UserOnboarding from './components/account/userOnboarding';
import Statements from './components/statements';
import Transactions from './components/transactions';

//Settings
import MDDs from './components/admin/mdd';
import Settings from './components/admin/settings';
import HouseModels from './components/admin/settings/houseModels';
import Reports from './components/admin/settings/reports';
import Products from './components/admin/settings/products';
import TransactionTypes from './components/admin/settings/types/transactionTypes';

//Admin
import Admin from './components/admin';
import Entities from './components/admin/entities';
import Funds from './components/admin/funds';
import Roles from './components/admin/roles';

//Auth
import ForgotPassword from './components/account/forgotPassword';
import SignIn from './components/account/signin';
import SignUp from './components/account/signup';
import UpdatePassword from './components/account/updatePassword';

//Communication
import Emails from './components/admin/communication/emails';

//Service Providers
import ServiceProviders from './components/admin/serviceProviders';
import Mappings from './components/admin/serviceProviders/mappings';

import ReactGA from 'react-ga4';
import OTPVerify from './components/account/OTPVerify';
import SignUpLanding from './components/account/signupLanding';
import Unauthorized from './components/account/unauthorized';
import Clients from './components/admin/clients';
import CreateRole from './components/admin/roles/createRole';
import Users from './components/admin/users';
import UserDetails from './components/admin/users/details';
import BrokerCodes from './components/advisor/addCodes';
import AdvisorSignUp from './components/advisor/advisorSignup';
import AdvisorClients from './components/advisor/clients';
import SubmitTicket from './components/home/submitTicket';
import Welcome from './components/home/welcome';
import Home from './components/home';
import AccountView from './components/account/accountView';
import AuthRoute from './hoc/auth/authRoute';
import OnboardingRoute from './hoc/onboardingRoute';
import ClientAccounts from './components/admin/clients/clientAccounts';
import ClientAccount from './components/admin/clients/clientAccount';

import AdvisorClientAccounts from './components/advisor/clientAccounts';
import AdvisorClientAccount from './components/advisor/clientAccount';
import AccountSettings from './components/account/profile/accountSettings';
import Contact from './components/home/contact';
import SelectClientAccount from './components/account/selectClientAccount';
import FamilyMemberOnboarding from './components/account/familyMemberOnboarding';
import EntityOnboarding from './components/account/entityOnboarding';
import StartHere from './components/home/starthere';
import ServiceProviderDetails from './components/admin/serviceProviders/details';
import Actions from './components/actions';
import StatementActions from './components/actions/statements';
import StatementsSelector from './components/statements/statementsSelector';
import ClientReports from './components/clientReports';
import GenerateClientDataReport from './components/admin/reports/generateClientDataReport';

ReactGA.initialize('G-LQY3F6T8M7', {
  gaOptions: { titleCase: false }
});

const MainRoutes = (props) => {
  let location = useLocation();
  React.useEffect(() => {}, [location]);

  return (
    <Routes>
      {/* Admin Routes */}
      <Route element={<AdminRoute userFunction={UserFunctions.ClientsAdmin} />}>
        <Route path="/admin/clients" element={<Clients />} title={'Clients'} />
        <Route path="/admin/clients/:id" element={<ClientAccounts />} title={'Clients'} />
        <Route path="/admin/client/account/:id" element={<ClientAccount />} title={'Clients'} />
        <Route
          path="/admin/reports/clients"
          element={<GenerateClientDataReport />}
          title={'Generate Client Data Report'}
        />
      </Route>

      <Route element={<AdminRoute userFunction={UserFunctions.ApplicaitonSettings} />}>
        {/* Settings */}
        <Route path={'/admin/settings'} element={<Settings />} title={'Settings'} />
        <Route path={'/admin/actions'} element={<Actions />} title={'Actions'} />
        <Route
          path={'/admin/actions/statements'}
          element={<StatementActions />}
          title={'Statements'}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Models} />}>
        <Route
          path={'/admin/settings/housemodels'}
          element={<HouseModels />}
          title={'House Models'}
        />
      </Route>

      <Route element={<AdminRoute userFunction={UserFunctions.Models} />}>
        <Route path={'/admin/settings/reports'} element={<Reports />} title={'Reports'} />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Products} />}>
        <Route path={'/admin/settings/products'} element={<Products />} title={'Products'} />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.TransactionTypes} />}>
        <Route
          path={'/admin/settings/transactiontypes'}
          element={<TransactionTypes />}
          title={'Transaction Types'}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Entities} />}>
        <Route
          path={'/admin/entities'}
          element={<Entities />}
          title={'Entities'}
          userFunction={UserFunctions.Entities}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Funds} />}>
        <Route
          path={'/admin/funds'}
          element={<Funds />}
          title={'Funds'}
          userFunction={UserFunctions.Funds}
        />
        <Route
          path={'/admin/funds/mdds'}
          element={<MDDs />}
          title={'MDDs'}
          userFunction={UserFunctions.Funds}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Roles} />}>
        <Route
          path={'/admin/roles'}
          element={<Roles />}
          title={'Roles'}
          userFunction={UserFunctions.Roles}
        />
        <Route
          path={'/admin/roles/create'}
          element={<CreateRole />}
          title={'Create Role'}
          userFunction={UserFunctions.Roles}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.Users} />}>
        <Route
          path={'/admin/users'}
          element={<Users />}
          title={'Users'}
          userFunction={UserFunctions.Users}
        />
        <Route
          path={'/admin/users/details/:id'}
          element={<UserDetails />}
          title={'User Details'}
          userFunction={UserFunctions.Users}
        />
      </Route>
      <Route element={<AdminRoute userFunction={UserFunctions.AdminView} />}>
        {/* Communication */}
        <Route
          path={'/admin/communication/emails'}
          element={<Emails />}
          title={'Email Communications'}
        />

        <Route path={'/admin'} element={<Admin />} title={'Dashboard'} />
      </Route>

      {/* Service Providers */}
      <Route element={<AdminRoute userFunction={UserFunctions.ServiceProviders} />}>
        <Route
          path={'/admin/serviceproviders'}
          element={<ServiceProviders />}
          title={'Service Providers'}
        />
        <Route
          path={'/admin/serviceproviders/:id'}
          element={<ServiceProviderDetails />}
          title={'Service Provider Details'}
        />
        <Route
          path={'/admin/serviceproviders/mappings/:id'}
          element={<Mappings />}
          title={'Service Provider Mappings'}
        />
      </Route>

      <Route element={<OnboardingRoute />}>
        <Route path={'/onboarding'} element={<UserOnboarding />} title="Onboarding" />
        <Route
          path={'/onboarding/family'}
          element={<FamilyMemberOnboarding />}
          title="Onboarding"
        />
        <Route path={'/onboarding/entity'} element={<EntityOnboarding />} title="Onboarding" />
      </Route>

      {/*Advisor Routes */}
      <Route element={<AdvisorRoute userFunction={UserFunctions.AdvisorAdmin} />}>
        <Route path={'/advisor/clients'} element={<AdvisorClients />} title={'Advisor Clients'} />
        <Route
          path="/advisor/clients/:id"
          element={<AdvisorClientAccounts />}
          title={'Client Accounts'}
        />
        <Route
          path="/advisor/client/account/:id"
          element={<AdvisorClientAccount />}
          title={'Client Account'}
        />
        <Route path={'/advisor/codes'} element={<BrokerCodes />} title={'Broker Codes'} />
        <Route path={'/advisor/submitTicket'} element={<SubmitTicket />} title={'Submit Ticket'} />
      </Route>

      {/* Accounts */}
      <Route element={<PrivateRoute userFunction={UserFunctions.GeneralAccess} />}>
        <Route path={'/starthere'} element={<StartHere />} title={'Start Here'} />
        <Route path={'/home'} element={<Home />} title={'Home'} />
        <Route path={'/contact'} element={<Contact />} title={'Contact'} />
        <Route path={'/unauthorized'} element={<Unauthorized />} title={'Unauthorized'} />
        <Route path={'/submitTicket'} element={<SubmitTicket />} title={'Submit Ticket'} />
        <Route path={'/user/profile'} element={<Profile />} title={'Your Profile'} />
        <Route path={'/user/account'} element={<AccountSettings />} title={'Account Settings'} />

        <Route
          path={'/transactions/:accountNumber'}
          element={<Transactions />}
          title={'Transactions'}
        />
        <Route path={'/clientreports'} element={<ClientReports />} title={'Reports'} />
        <Route
          path={'/statements-selector'}
          element={<StatementsSelector />}
          title={'Select Account'}
        />
        <Route path={'/statements/:accountNumber'} element={<Statements />} title={'Statements'} />
        <Route path={'/account/:accountNumber'} element={<AccountView />} title={'Account'} />
        <Route path={'/balances/:clientId?'} element={<Balances />} title={'Balances'} />
        <Route
          path={'/select-client-account'}
          element={<SelectClientAccount />}
          title={'Select Account to View'}
        />
        {/* <Route path={'/'} element={<Summary />} title={'Balances'} /> */}
      </Route>

      <Route element={<VerifyRoute />}>
        <Route path={'/verify/otp'} element={<OTPVerify />} />
      </Route>

      <Route element={<AuthRoute />}>
        {/* Auth Routes */}

        <Route path={'/signin'} element={<SignIn />} />
        <Route path={'/client/signup'} element={<SignUp />} />
        <Route path={'/advisor/signup'} element={<AdvisorSignUp />} />
        <Route path={'/signup/select'} element={<SignUpLanding />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/reset-password/:token'} element={<UpdatePassword />} />
      </Route>

      <Route element={<PublicRoute />}>
        {/* Auth Routes */}
        <Route path={'/'} element={<Welcome />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
