import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAlertError, setAlertSuccess } from '../alert/alert.slice';
import { notificationService } from './notification.service';

const initialState = {
  selectedNotification: {},
  notifications: [],
  unseenCount: 0,
  loading: false,
  schedulingStatements: false
};

export const getUnseenCount = createAsyncThunk(
  'notification/getUnseenCount',
  async (_, thunkAPI) => {
    return await notificationService
      .getUnseenCount()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async (_, thunkAPI) => {
    return await notificationService
      .getAll()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const getNotification = createAsyncThunk(
  'notification/getNotification',
  async (id, thunkAPI) => {
    return await notificationService
      .get(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const dismissNotification = createAsyncThunk(
  'notification/updateNotification',
  async (data, thunkAPI) => {
    const newData = { ...data, dismissed: true };
    return await notificationService
      .dismiss(newData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const markNotificationAsSeen = createAsyncThunk(
  'notification/markAsSeen',
  async (data, thunkAPI) => {
    return await notificationService
      .markAsSeen(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const updateNotification = createAsyncThunk(
  'notification/updateNotification',
  async (data, thunkAPI) => {
    return await notificationService
      .update(data._id, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const createNotification = createAsyncThunk(
  'notification/createNotification',
  async (data, thunkAPI) => {
    return await notificationService
      .create(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Notification created successfully'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const deleteNotification = createAsyncThunk(
  'notification/deleteNotification',
  async (id, thunkAPI) => {
    return await notificationService
      .remove(id)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Notification deleted'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const scheduleStatementNotifications = createAsyncThunk(
  'notification/scheduleStatementNotifications',
  async (data, thunkAPI) => {
    return await notificationService
      .scheduleStatements(data)
      .then((response) => {
        thunkAPI.dispatch(setAlertSuccess('Statement Notifications Scheduled'));
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        thunkAPI.dispatch(setAlertError(err.msg));
        thunkAPI.rejectWithValue(err.msg);
      });
  }
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    selectNotification: (state, action) => {
      state.selectedNotification = action.payload;
    }
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications = action.payload.notifications;
    },
    [getNotifications.rejected]: (state) => {
      state.loading = false;
    },
    [getNotification.pending]: (state) => {
      state.loading = true;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.notification = action.payload.notification;
    },
    [getNotification.rejected]: (state) => {
      state.loading = false;
    },
    [updateNotification.pending]: (state) => {
      state.loading = true;
    },
    [updateNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateNotification.rejected]: (state) => {
      state.loading = false;
    },
    [createNotification.pending]: (state) => {
      state.loading = true;
    },
    [createNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [createNotification.rejected]: (state) => {
      state.loading = false;
    },
    [deleteNotification.pending]: (state) => {
      state.loading = true;
    },
    [deleteNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteNotification.rejected]: (state) => {
      state.loading = false;
    },
    [dismissNotification.pending]: (state) => {
      state.loading = true;
    },
    [dismissNotification.fulfilled]: (state) => {
      state.loading = false;
    },
    [dismissNotification.rejected]: (state) => {
      state.loading = false;
    },
    [scheduleStatementNotifications.pending]: (state) => {
      state.schedulingStatements = true;
    },
    [scheduleStatementNotifications.fulfilled]: (state) => {
      state.schedulingStatements = false;
    },
    [scheduleStatementNotifications.rejected]: (state) => {
      state.schedulingStatements = false;
    },
    [getUnseenCount.pending]: (state) => {
      state.loading = true;
    },
    [getUnseenCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.unseenCount = action.payload.unseenCount;
    },
    [getUnseenCount.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const { selectNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
