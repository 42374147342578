import { config } from '../config';
import { authHeader } from '../helpers';
import axios from 'axios';

export const fundService = {
    getAllFunds,
    getFund,
    createFund
};

function getAllFunds(query) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        params: query
    };



    return axios.get(`${config.fund_server}`, requestOptions)
        .then(responce => {
            return responce.data;
        })
}

function getFund(id) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return axios.get(`${config.fund_server}/${id}`, requestOptions)
        .then(responce => {
            return responce.data;
        })
}

function createFund(data) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return axios.post(`${config.fund_server}/`, data, requestOptions)
        .then(responce => {
            return responce.data;
        })
}