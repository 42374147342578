import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const ReportProgress = ({ jobId }) => {
  const wsUrl =
    process.env.NODE_ENV === 'production'
      ? `wss://yourdomain.com/ws?type=job&jobId=${jobId}`
      : `ws://localhost:3005/ws?type=job&jobId=${jobId}`;

  console.log('WebSocket URL:', wsUrl); // Debugging statement

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => console.log('WebSocket connection opened'),
    onClose: () => console.log('WebSocket connection closed'),
    onError: (error) => console.log('WebSocket error', error),
    shouldReconnect: (closeEvent) => true // Reconnect on close
  });

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('Waiting for progress...');

  useEffect(() => {
    console.log('Connection state changed');
    console.log('Ready state:', readyState);

    if (jobId) {
      switch (readyState) {
        case ReadyState.OPEN: {
          console.log('Send JSON Message');

          sendJsonMessage({
            event: 'subscribe',
            data: {
              channel: 'portal-reports'
            }
          });
          break;
        }
        case ReadyState.CLOSED: {
          console.log('Connection closed');
          break;
        }
        default:
          break;
      }
    }
  }, [readyState, sendJsonMessage, jobId]);

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.data) {
      const data = lastJsonMessage.data;
      if (data.progress !== undefined) {
        setProgress(data.progress);
        setMessage(`Progress: ${data.progress}%`);
      }
    }
  }, [lastJsonMessage]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h4" gutterBottom>
        Report Progress {jobId}
      </Typography>
      <LinearProgress variant="determinate" value={progress} style={{ width: '80%' }} />
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        {message}
      </Typography>
    </Box>
  );
};

export default ReportProgress;
